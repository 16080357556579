/**
 * ProfileSubjectCard scss module
 *
 * @author: exode <hello@exode.ru>
 */

@import 'src/assets/styles/modules/responsive';

@mixin svgSize($size) {
  svg {
    width: $size;
    height: $size;
  }
}

.bigSubjectCard {
  height: 135px;

  :global(.vkuiCard__in) {
    @include svgSize(70px);
    margin: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.selectedSubjectsCard {
  max-height: 115px;

  :global(.vkuiText) {
    font-size: 12px;
  }

  :global(.vkuiCard__in) {
    @include svgSize(50px);
  }
}
