/**
 * Emoji-picker scss module
 *
 * @author: exode <hello@exode.ru>
 */

.emoji-picker-react {
  background: var(--modal_card_background) !important;

  & .emoji-group:before {
    position: inherit !important;
    background: transparent !important;
  }
}

body[scheme=space_gray] .emoji-picker-react {
  border: none !important;

  & .emoji-categories {
    filter: invert(1) !important;
  }
}
