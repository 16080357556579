/**
 * Common scss module
 *
 * @author: exode <hello@exode.ru>
 */

*, ::before, ::after {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none !important;
}

html {
  background-color: var(--html_background) !important;

  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-right: env(safe-area-inset-right);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  --safe-area-inset-left: env(safe-area-inset-left);
}

::selection {
  body[scheme="space_gray"] & {
    background: var(--modal_card_border);
  }
}

input:placeholder-shown {
  text-overflow: ellipsis;
}

.inner-iframe {
  /** Fallback */
  height: calc(100vh - var(--panelheader_height));
  height: calc(100dvh - var(--panelheader_height));
}

.next-release {
  display: none !important;
}
