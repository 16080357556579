/**
 * Animation scss module
 *
 * @author: exode <hello@exode.ru>
 */

@keyframes slide-in {
  from {
    transform: translateX(-200%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes init-block {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes scale-up-and-down {
  0% {
    transform: scale(1.3);
    opacity: 1;
  }

  100% {
    transform: scale(0.75);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    box-shadow: #ffffff 0 0 0 0;
  }

  100% {
    box-shadow: #ffffff00 0 0 0 10px;
  }
}

@keyframes scale-up {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.25) rotate(-10deg);
  }

  50% {
    transform: scale(2.35) rotate(10deg);
  }

  80% {
    transform: scale(1.25) rotate(-10deg);
  }

  100% {
    transform: scale(1);
  }
}

.slide-in-animation {
  animation: slide-in .2s ease-in-out;
  animation-fill-mode: forwards;
}

.shake-animation {
  animation: shake 1s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.scale-animation {
  animation: scale-up-and-down 0.5s alternate infinite ease-in;
}

.scale-animation {
  animation: scale-up-and-down 0.5s alternate infinite ease-in;
}

.pulse-animation {
  animation: pulse 2s infinite;
}

.scale-up-animation {
  animation: scale-up 1s ease-in-out forwards;
}
