/**
 * StickyButton scss module
 *
 * @author: exode <hello@exode.ru>
 */

@import 'src/assets/styles/modules/responsive';
@import 'src/assets/styles/modules/shadow';
@import 'src/assets/styles/modules/positioning';

.stickyButton {
  @include sticky;

  @include mobile() {
    bottom: calc((env(safe-area-inset-bottom, 0px)) - 1px);
  }
}

.stickyButtonAtTab {
  @include sticky;

  @include mobile() {
    bottom: calc(env(safe-area-inset-bottom, 0px) + var(--tabbar_height));
  }
}
