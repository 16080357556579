/**
 * Tooltip scss module
 *
 * @author: exode <hello@exode.ru>
 */

.TextTooltip {
  &[data-popper-placement^=left] {
    .PopperArrow {
      margin-left: -0.095em;
    }
  }

  &[data-popper-placement^=right] {
    .PopperArrow {
      margin-right: -0.095em;
    }
  }

  &[data-popper-placement^=bottom] {
    .PopperArrow {
      margin-bottom: -0.08em;
    }
  }

  &[data-popper-placement^=top] {
    .PopperArrow {
      margin-top: -0.08em;
    }
  }

  &--accent {
    background: var(--accent) !important;

    .vkuiTextTooltip__arrow {
      color: var(--accent) !important;
    }
  }

  &--green {
    background: var(--dynamic_green) !important;

    .vkuiTextTooltip__arrow {
      color: var(--dynamic_green) !important;
    }
  }
}

.vkuiPopper__content {
  user-select: none;
  border-radius: var(--vkui--size_border_radius_paper--regular) !important;
  border: var(--vkui_internal--thin_border) solid var(--vkui--color_field_border_alpha);
}

.vkuiCalendar {
  border-radius: var(--vkui--size_border_radius_paper--regular) !important;
}

.tooltip-no-arrow {
  padding: 6px 8px;

  .vkuiTextTooltip__text {
    margin-bottom: 1px;
  }

  .vkuiPopper__arrow {
    display: none;
  }
}
