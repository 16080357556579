/**
 * Pagination.module scss module
 *
 * @author: exode <hello@exode.ru>
 */

@import 'src/assets/styles/modules/background';
@import 'src/assets/styles/modules/responsive';

.stickyContainer {
  z-index: 2;
  bottom: -1px;
  position: sticky;
  padding-bottom: 1px;
  margin-bottom: 1rem;

  @include mobile() {
    margin-top: 0;
    margin-bottom: 0;
    bottom: calc(env(safe-area-inset-bottom, 0) + 48px);
  }
}

.centeredPagination {
  @extend .bg-content-75;
  @include backdrop-filter(20px);

  display: flex;
  padding: 10px 0;
  overflow: hidden;
  justify-content: center;
  border-radius: var(--vkui--size_border_radius_paper--regular);

  @include mobile() {
    @include bg-content(0.95);

    margin-bottom: 0;
    border-radius: 0;
    backdrop-filter: none;
  }
}

.centeredPaginationRounded {
  @extend .centeredPagination;

  padding: 6px 0;
  box-shadow: none;
  background-color: inherit !important;
  border-radius: var(--vkui--size_border_radius_paper--regular);

  @include mobile() {
    border-radius: 0;
    margin-bottom: 1rem;
  }
}
