/**
 * VK UI override tab header panel
 *
 * @author: exode <hello@exode.ru>
 */

@import "../modules/gradient";
@import "../modules/text";

.panel-feed__active {
  body[scheme=bright_light] & {
    @include blue-light-blue-gradient(45, var(--ocean_blue));
    @include text-gradient();
  }

  body[scheme=space_gray] & {
    @include blue-light-blue-gradient(45, var(--ocean_blue));
    @include text-gradient();
  }
}
