/**
 * Variables
 *
 * @author: exode <hello@exode.ru>
 */

@import "./responsive";

/** CSS variables */

.vkui__root,
:root {
  --app-height: 100dvh - env(safe-area-inset-top) - env(safe-area-inset-bottom);
  --statusbar_height: 0;
  --panelheader_height: 0;
  --thin-border: 0.3px;
  --vkui--z_index_modal: 11;
  --vkui--z_index_popout: 11;
  --modal_backdrop: 3px;
  --fs-header: 20px;
  --fs-content: calc(var(--vkui--font_text--font_size--regular, 16px) + 2px);

  @include gt-tablet() {
    --thin-border: 0.5px !important;
  }
}

.vkui__root,
body[platform="android"],
body[target-os-platform="android"] {
  --panelheader_height: var(--panelheader_height_android);

  @include gt-tablet() {
    --panelheader_height: 50px !important;
  }
}

body[platform="ios"],
body[target-os-platform="ios"] {
  --panelheader_height: var(--panelheader_height_ios);
}

body[platform="vk"],
body[target-os-platform="vk"] {
  --panelheader_height: var(--panelheader_height_vkcom);
}

body[target-os-platform="ios"],
body[target-os-platform="android"],
body[target-os-platform="vk"] {
  --statusbar_height: 0;
}
