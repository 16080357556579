/**
 * ModalCard scss module
 *
 * @author: exode <hello@exode.ru>
 */

.disableManualClose {
  :global(.ModalDismissButton),
  :global(.vkuiModalCardBase__dismiss) {
    display: none;
  }
}
