/**
 * SizedModal scss module
 *
 * @author: exode <hello@exode.ru>
 */

.medium {
  :global(.vkuiModalCard__in) :global(.vkuiModalCardBase__container) {
    width: 670px;
    max-width: 100vw;

    body[scheme=bright_light] & {
      background-image: linear-gradient(0deg, #f1f1f1bf, var(--background_content));
    }
  }

  :global(.vkuiModalCard__in) {
    max-width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
