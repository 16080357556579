/**
 * Title scss module
 *
 * @author: exode <hello@exode.ru>
 */

.Title {
  &:not(.Button__content) {
    font-family: var(--font-display);
  }
}

.modal-title {
  margin-top: 0.5rem;
}
