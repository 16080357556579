/**
 * Story scss module
 *
 * @author: exode <hello@exode.ru>
 */

@import "src/assets/styles/modules/responsive";

.storyModal {
  :global(.vkuiModalCard__in) {
    @include gt-mobile() {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  :global(.vkuiModalCard__in) :global(.vkuiModalCardBase__container) {
    padding: 0;
    width: 440px;

    @include mobile() {
      top: calc(8px + env(safe-area-inset-bottom, 0px));
      left: -8px;
      max-width: 100vw;
    }
  }

  :global(.vkuiModalCardBase__container.ModalCardBase__container) {
    @include mobile() {
      > div:first-child {
        background: black !important;
      }
    }
  }

  :global(.ModalDismissButton) {
    display: none;
  }

  :global(.ModalCardBase__dismiss) {
    @include tablet() {
      display: none;
    }
  }
}
