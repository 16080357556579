/**
 * Shadow module and variables
 *
 * @author: exode <hello@exode.ru>
 */

@import '../modules/scheme';
@import '../modules/responsive';
@import '../modules/positioning';


@mixin transparent-shadow {
  pointer-events: none;

  @include light() {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  }

  @include dark() {
    background: linear-gradient(0deg, rgba(35, 35, 36, 0) 0%, rgb(35, 35, 36) 100%);
  }

  @include mobile() {
    @include dark() {
      background: linear-gradient(0deg, rgba(25, 25, 26, 0) 0%, rgb(25, 25, 26) 100%);
    }
  }
}

.vk-shadow {
  box-shadow: var(--vkui--elevation3, 0 2px 24px 0 rgba(0, 0, 0, .08), 0 0 2px 0 rgba(0, 0, 0, .08));
}

/** For light scheme */

body[scheme=bright_light] {
  --panel_header_shadow: rgba(1, 1, 1, 0.2);
}


/** For dark scheme */

body[scheme=space_gray] {
  --panel_header_shadow: rgba(0, 0, 0, 0.5)
}

@mixin line-shadow($inner: var(--iris_blue), $outer: var(--background_content)) {
  box-shadow: inset 0 0 0 2px $inner, inset 0 0 0 4px $outer;
}

.line-shadow {
  &--iris {
    @include line-shadow();
  }
}

.linear-shadow {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

.white-glow {
  box-shadow: 0 0 23px rgba(255, 255, 255, 0.3);
}

.forward-shadow {
  box-shadow: rgba(0, 0, 0, 0.19) 0 10px 20px, rgba(0, 0, 0, 0.23) 0 6px 6px;
}

.content-shadow {
  .ModalCard &,
  .ModalPage & {
    box-shadow: var(--modal_card_background) 0 0 10px 10px;
  }

  box-shadow: var(--background_content) 0 0 10px 10px;
}

.transparent-shadow {
  @include transparent-shadow()
}
