/**
 * CellTagSelect scss module
 *
 * @author: exode <hello@exode.ru>
 */

.simpleCell {
  & {
    :global(.vkuiSimpleCell__main) {
      padding: 0 !important;
    }
  }
}
