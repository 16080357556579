/**
 * PhotoView scss module
 *
 * @author: exode <hello@exode.ru>
 */

.PhotoView-Portal {
  z-index: 9999;
  font-family: -apple-system, system-ui, Helvetica Neue, Roboto, sans-serif;
}

.PhotoView-Slider__BannerWrap {
  body[is-native="true"] &,
  body[is-vk="true"] & {
    padding-top: calc(env(safe-area-inset-top, 0px));
  }
}
