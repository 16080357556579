/**
 * Viewport scss module
 *
 * @author: exode <hello@exode.ru>
 */

$desktop-menu-width: 230px;
$desktop-menu-slim-width: 230px;

$desktop-content-small-width: 510px;
$desktop-content-medium-width: 610px;
$desktop-content-large-width: 650px;

$context-width: 312px;
$wrapper-max-width: 1480px;

@import "responsive";

:root {
  --desktop-content-width: #{$desktop-content-small-width};

  @include desktop-lg() {
    --desktop-content-width: #{$desktop-content-large-width};
  }
}

.viewport {
  display: flex;
  flex-direction: column;

  &__container {
    max-width: calc($desktop-menu-width + $desktop-content-small-width + $context-width);

    @include desktop-lg() {
      max-width: calc($desktop-menu-width + $desktop-content-large-width + $context-width);
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: auto;

    &:not([data-page-mode="slim-menu"]) {
      max-width: $wrapper-max-width;
    }

    [data-with-manage-drawer="true"]:not([data-page-type="iframe"]) & {
      &:not([data-page-mode="slim-menu"]) {
        @include desktop-lg() {
          padding-right: 0;
          width: 100%;
        }
      }
    }

    &[data-page-mode="slim-menu"] {
      @include gt-tablet() {
        padding-left: 70px;
        width: calc(100% - 70px);
      }
    }

    [data-with-manage-drawer="true"] & {
      @include gt-tablet() {
        padding-right: 90px;
        width: calc(100% - 90px);

        &[data-page-mode="slim-menu"] {
          width: calc(100% - 90px - 70px);
        }
      }
    }

    &--full {
      max-width: 100% !important;

      .viewport__content {
        max-width: 100%;
        min-width: 100%;
        margin: 0;
      }
    }

    .context-sticky {
      @include tablet-slim() {
        display: none !important;
      }
    }
  }

  &__left-menu {
    width: $desktop-menu-width;
    max-width: $desktop-menu-width;

    [data-page-mode="slim-menu"] & {
      width: $desktop-menu-slim-width;
      max-width: $desktop-menu-slim-width;
    }

    @media (min-width: $desktop-from) and (max-width: $tablet-full-from) {
      width: 45px;
      max-width: 45px;
    }
  }

  &__content {
    width: 100%;
    z-index: 1;

    @include gt-tablet() {
      max-width: $desktop-content-small-width;

      [data-page-type="fullscreen"] & {
        max-width: calc($desktop-content-large-width + 60px);
      }

      [data-page-mode="fillable"] & {
        max-width: calc($desktop-content-small-width + $context-width);
      }

      [data-page-mode="slim-menu"] & {
        max-width: 100%;
      }

      @include laptop() {
        max-width: $desktop-content-small-width;

        [data-page-mode="fillable"] & {
          max-width: calc($desktop-content-small-width + $context-width);
        }

        [data-page-mode="slim-menu"] & {
          max-width: 100%;
        }
      }

      @include desktop-md() {
        max-width: $desktop-content-medium-width;

        [data-page-mode="fillable"] & {
          max-width: calc($desktop-content-medium-width + $context-width);
        }

        [data-page-mode="slim-menu"] & {
          max-width: 100%;
        }
      }

      @include desktop-lg() {
        max-width: $desktop-content-large-width;

        [data-page-mode="fillable"] & {
          max-width: calc($desktop-content-large-width + $context-width);
        }

        [data-page-mode="slim-menu"] & {
          max-width: 100%;
        }
      }
    }

    @media (min-width: 951px) and (max-width: 1023px) {
      max-width: 496px;
    }

    @include tablet-slim() {
      &.viewport__content--page {
        max-width: 95%;
      }

      &:not(.viewport__content--page) {
        margin-right: 0;

        .PanelHeaderContent__children {
          max-width: 200px;
        }
      }
    }
  }

  &__right-menu {
    width: 280px;
    max-width: 280px;

    @include tablet-slim() {
      width: 352px;
      max-width: 352px;
      min-width: 352px;
    }

    .is-scrollable-context & {
      overflow-y: auto;
      max-height: calc(100vh - var(--panelheader_height));

      @include tablet-slim() {
        display: none;
      }

      * [data-is-windows="true"] {
        @extend .scrollbar-hidden;
      }
    }
  }

  &.centered {
    .viewport__wrapper,
    .viewport__left-menu,
    .viewport__content,
    .viewport__right-menu {
      display: flex;
      height: 100%;

      > * {
        width: 100%;
      }
    }
  }
}
