/**
 * ProfileNotificationsPage scss module
 *
 * @author: exode <hello@exode.ru>
 */

.newNotificationsCount {
  :global(.vkuiHeader__aside) {
    margin-left: 0;
  }

  :global(.vkuiHeader__content-in) {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
}