/**
 * Color scheme variables
 *
 * @author: exode <hello@exode.ru>
 */


@mixin light {
  body[scheme=bright_light] & {
    @content;
  }
}

@mixin dark {
  body[scheme=space_gray] & {
    @content;
  }
}

/** Common for all schemas  */

:root {
  --exode_red: #ED1C24;
  --exode_orange: #FF9A44;
  --exode_blue: #003EBC;
  --exode_purple: #6A11CB;
}

:root {
  --blue_light: #0077ff;
  --blue_light_highlight: rgba(73, 134, 204, .08);
  --blue_light_transparent: #0077ff30;
  --green_extra_light: #65e065;
  --green_light_transparent: #08F11B3F;
  --ocean_blue: #1353d6;
  --iris_blue: #4b56cc;
  --azure_blue: #0050f2;
  --rate_gold: #faaf00;
  --gray_smoked: #323639;
  --gray_gunmetal: #757779;
  --black_jet: #0a0a0a;
}


/** For light scheme */

body[scheme=bright_light] {
  --vk_button_bg: #4986cc;
  --tooltip_black_bg: #464647;
  --plyr-color-main: #3f67e0;
}


/** For dark scheme */

body[scheme=space_gray] {
  --vk_button_bg: #e1e3e6;
  --tooltip_black_bg: #2a2a2b;
  --plyr-color-main: #3a9cfc;
}
