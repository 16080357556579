/**
 * Popover mui scss module
 *
 * @author: exode <hello@exode.ru>
 */

.MuiPaper-root {

}

.manage-drawer {

  > .MuiPaper-root {
    right: 89.5px;
  }
}

.MuiPaper-root {
  box-shadow: 0 0 3px var(--panel_header_shadow) !important;

  .drawer-container-p & {
    padding: 10px;
  }
}

.MuiPopover-paper {
  @extend .font-vk-sans-display;

  background: var(--background_content) !important;
  border-radius: var(--vkui--size_border_radius_paper--regular) !important;
  border: .5px solid var(--vkui--color_separator_primary, #d7d8d9) !important;
  box-shadow: var(--vkui--elevation1, 0 0 2px rgba(0, 0, 0, .03), 0 2px 2px rgba(0, 0, 0, .06)) !important;

  /** Color picker */
  .MuiColorInput-PopoverBody {
    .MuiColorInput-ColorSpace {
      border-radius: var(--vkui--size_border_radius_paper--regular) !important;
    }

    .MuiSlider-thumbColorPrimary {
      color: var(--accent);
    }
  }
}

