/**
 * Responsive module
 *
 * @author: exode <hello@exode.ru>
 */

$mobile-to: 480px;
$desktop-from: 768px;
$laptop-from: 1024px;
$desktop-sm-from: 1200px;
$desktop-md-from: 1326px;
$desktop-lg-from: 1455px;

$tablet-full-from: 1208px;


@mixin mobile {
  @media (max-width: $mobile-to) {
    @content;
  }
}

@mixin gt-mobile {
  @media (min-width: $mobile-to) {
    @content;
  }
}

@mixin gt-small-height {
  @media screen and (min-width: $desktop-from) and (max-height: $desktop-from) and (max-width: $laptop-from) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $desktop-from) {
    @content;
  }
}

@mixin tablet-slim {
  @media (min-width: $desktop-from) and (max-width: 950px) {
    @content;
  }
}

@mixin gt-tablet {
  @media (min-width: $desktop-from) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: $laptop-from) {
    @content;
  }
}

@mixin gt-laptop {
  @media (min-width: $laptop-from) {
    @content;
  }
}

@mixin desktop-sm {
  @media (max-width: $desktop-sm-from) {
    @content;
  }
}

@mixin desktop-md {
  @media (min-width: $desktop-md-from) {
    @content;
  }
}

@mixin gt-desktop-sm {
  @media (min-width: $desktop-sm-from) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: 1201px) {
    @content;
  }
}

@mixin gt-desktop {
  @media (min-width: 1201px) {
    @content;
  }
}

@mixin desktop-lg {
  @media (min-width: $desktop-lg-from) {
    @content;
  }
}

@mixin full-screen {
  transition: min-height 0.2s ease;

  body[platform="ios"] &,
  body[platform="android"] & {
    @include tablet() {
      min-height: calc(var(--app-height) - var(--panelheader_height));
    }

    @include gt-tablet() {
      min-height: calc(var(--app-height) - var(--panelheader_height) - 20px);
    }
  }

  body[platform="ios"] & {
    min-height: calc(var(--app-height) - var(--panelheader_height) + var(--safe-area-inset-bottom));
  }

  &.with-tabbar {
    body[platform="ios"] &,
    body[platform="android"] & {
      @include tablet() {
        min-height: calc(var(--app-height) - var(--panelheader_height) - var(--tabbar_height) + var(--safe-area-inset-bottom));
      }

      @include gt-tablet() {
        min-height: calc(var(--app-height) - var(--panelheader_height) - 20px);
      }
    }
  }

  &.without-header {
    body[platform="vk"] &,
    body[platform="ios"] &,
    body[platform="android"] & {
      @include tablet() {
        min-height: calc(var(--app-height));
      }

      @include gt-tablet() {
        min-height: calc(var(--app-height));
        padding-bottom: 4px;
      }
    }
  }
}

[data-page-space="false"] {
  #epic-root > .Panel__in {
    padding-bottom: 0 !important;
  }
}

.full-screen {
  @include full-screen();
}
