/**
 * Sizing scss module
 *
 * @author: exode <hello@exode.ru>
 */

.h-fit {
  height: fit-content;
}

.h-screen {
  height: calc(var(--app-height)) !important;
}

.h-panel-header {
  height: var(--panelheader_height);
  min-height: var(--panelheader_height);
}

.h-full-\[-panel-header\] {
  height: calc(100% - var(--panelheader_height));
}

.w-fit {
  width: fit-content;
}

.fit-cover {
  object-fit: cover;
}
