/**
 * ChatDialogsPage.module scss module
 *
 * @author: exode <hello@exode.ru>
 */

.writeBar {
  :global(.vkuiWriteBar__before) {
    min-width: 44px;
    min-height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--icon_secondary, var(--vkui--color_icon_secondary));
  }

  :global(.vkuiWriteBar__textarea) {
    max-height: 220px;
  }
}

.filePreview {
  width: 100px !important;

  :global(.vkuiAvatar__img) {
    object-fit: contain;
  }

  :global(.vkuiIcon--cancel_circle_16) {
    border-radius: 50%;
    position: absolute;
    bottom: 45px;
    right: -20px;
    cursor: pointer;
    opacity: 0.95;
  }
}

.chip {
  position: absolute;
  height: 15px;
  max-width: 100px;

  :global(.vkuiChip__in) {
    padding: 0 4px;
  }

  :global(.vkuiChip__content) {
    font-size: 10px;
  }
}