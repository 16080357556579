/**
 * Editor js scss module
 *
 * @NOTE: This file override only EditorJS in edit mode
 * @See EditorJsOutput.styled.tsx for show mode
 *
 * @author: exode <hello@exode.ru>
 */

@import "background";

$default-bg: var(--modal_card_background);
$hover-bg: var(--field_background, var(--vkui--color_field_background));

.codex-editor--narrow .codex-editor__redactor {
  margin-right: 0 !important;
}

.ct {
  &--bottom,
  &--top {
    font-family: "VK Sans Display", -apple-system, system-ui, Helvetica Neue, Roboto, sans-serif;
  }
}

.ce-toolbar__actions,
.ce-inline-tool {
  padding: 0 10px !important;
  min-width: 34px;

  .ce-toolbar__actions-buttons {
    @extend .thin-border;

    border-radius: 2px;
    overflow: hidden;
    background-color: var(--background_content);

    body[scheme=bright_light] & {
      box-shadow: var(--vkui--elevation3, 0 2px 24px 0 rgba(0, 0, 0, .08), 0 0 2px 0 rgba(0, 0, 0, .08));
    }
  }

  .ce-toolbar__plus,
  .ce-toolbar__settings-btn {
    @extend .thin-border;
    @include backdrop-filter(4px);
    min-width: 26px;
    margin: 0 4px 0 0;
    background-color: var(--field_background);
  }

  .ce-toolbar__settings-btn:hover,
  .ce-toolbar__plus:hover {
    background-color: var(--background_keyboard);
  }

  svg {
    color: var(--text_primary);
  }
}

.ce-toolbox__button,
.ce-inline-toolbar,
.ce-conversion-toolbar {

  &.ce-toolbox__button {
    border: 1px solid transparent;
  }

  body[scheme=space_gray] & {
    &:not(.ce-toolbox__button--active) {
      color: var(--white) !important;
    }

    &:hover:not(.ce-toolbox__button--active),
    &.ce-inline-toolbar,
    &.ce-conversion-toolbar {
      .ce-inline-toolbar__dropdown,
      .ce-inline-tool,
      .ce-conversion-tool {
        &:hover {
          background-color: var(--background_content);
        }
      }

      background: var(--background_light);
      border: 1px solid var(--modal_card_border);
    }

    .ce-conversion-tool {
      .ce-conversion-tool__icon {
        background: $default-bg;
      }
    }
  }

  &.ce-inline-toolbar {
    .ce-inline-toolbar__toggler-and-button-wrapper {
      overflow: hidden;
      border-radius: 5px;
    }
  }

  .ce-conversion-tool {
    &:last-child {
      border-radius: 0 0 5px 5px;
    }
  }
}

.ce-block--focused {
  .ce-block__content {
    @extend .thin-border;
  }
}

.ce-block--selected {
  .ce-block__content {
    @extend .thin-border;

    background: rgba(0, 28, 61, 0.03);
  }

  body[scheme=space_gray] & {
    .ce-block__content {
      background: $default-bg;
    }
  }
}

.ce-block__content {
  padding: 0 8px;
  min-height: 38px;
  border-radius: var(--vkui--size_border_radius--regular) !important;
}

.ce-settings {
  body[scheme=space_gray] & {
    @extend .thin-border;

    border: none;
    background-color: $default-bg;

    .ce-settings__button:hover {
      background-color: var(--background_highlighted);
    }
  }
}

.ce-header {
  padding: 0.4em 0.4em 0.4em 3px;
}

.cdx-input {
  box-shadow: none !important;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: var(--vkui--size_border_radius--regular);
  color: var(--text_primary, var(--vkui--color_text_primary)) !important;
}

.ce-rawtool__textarea {
  color: var(--white) !important;
}

.cdx-settings-button {

  body[scheme=space_gray] & {
    &:hover {
      background-color: var(--background_highlighted);
    }
  }
}

.cdx-checklist__item {
  word-break: break-all;

  &:hover {
    svg {
      opacity: 0.8;
      color: var(--icon_secondary);
    }
  }

  .cdx-checklist__item-checkbox-check {
    border-color: var(--vkui--color_separator_primary);
  }

  &--checked {
    .cdx-checklist__item-checkbox-check {
      background: var(--accent);
      border-color: var(--accent);

      &:hover {
        background: var(--exode_blue) !important;
        border-color: var(--exode_blue) !important;
      }

      &:before {
        background-color: var(--accent);
      }
    }
  }
}

.cdx-notify {
  background-color: var(--background_content) !important;
}

.link-tool {
  &__input {
    body[scheme=space_gray] & {
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.567 13.629c.728.464 1.581.65 2.41.558l-.873.873A3.722 3.722 0 1 1 4.84 9.794L6.694 7.94a3.722 3.722 0 0 1 5.256-.008L10.484 9.4a5.209 5.209 0 0 1-.017.016 1.625 1.625 0 0 0-2.29.009l-1.854 1.854a1.626 1.626 0 0 0 2.244 2.35zm2.766-7.358a3.722 3.722 0 0 0-2.41-.558l.873-.873a3.722 3.722 0 1 1 5.264 5.266l-1.854 1.854a3.722 3.722 0 0 1-5.256.008L9.416 10.5a5.2 5.2 0 0 1 .017-.016 1.625 1.625 0 0 0 2.29-.009l1.854-1.854a1.626 1.626 0 0 0-2.244-2.35z' fill='rgba(255, 255, 255, 0.6)' transform='translate(-3.667 -2.7)'/%3E%3C/svg%3E%0A");
    }

    &[contentEditable=true][data-placeholder]::before {
      color: var(--field_text_placeholder);
    }

    &-holder {
      &--error {
        .link-tool__input {
          border-color: var(--field_error_border, var(--vkui--color_stroke_negative));
          background-color: var(--field_error_background, var(--vkui--color_background_negative_tint));
        }
      }
    }
  }

  &__title {
    color: var(--text_primary);
  }

  &__description {
    color: var(--text_secondary);
  }

  &__progress {
    overflow: hidden;
    border-radius: var(--vkui--size_border_radius--regular);

    &--loading {
      background-color: var(--field_background, var(--vkui--color_field_background)) !important;
    }

    &--loaded {
      background-color: var(--background_content) !important;
    }
  }
}

.link-tool__content--rendered {
  box-shadow: none;
  background-color: var(--background_content);
  border-radius: var(--vkui--size_border_radius--regular);
  border: var(--thin-border) solid var(--vkui--color_separator_primary);

  &:hover {
    box-shadow: none;
  }
}

.ce-popover,
.tc-popover {
  border-radius: var(--vkui--size_border_radius--regular);
  border-color: var(--vkui--color_separator_primary);

  .ce-popover__search {
    display: none;
    background: var(--field_background);

    &__input {
      color: var(--text_primary);
    }
  }

  body[scheme=space_gray] & {
    background-color: $default-bg;

    svg {
      color: rgb(0, 0, 0);
    }

    .cdx-search-field__icon {
      svg {
        color: var(--icon_secondary);
      }
    }

    .ce-popover-item,
    .tc-popover__item {
      &__icon,
      &-icon {
        box-shadow: none;
        border-color: var(--background_highlighted);
      }

      &--confirm {
        svg {
          color: var(--white);
        }
      }

      &__title {
        color: var(--text_primary);
      }

      &:hover:not(.ce-popover-item--confirmation):not(.tc-popover__item--confirm) {
        background-color: var(--background_highlighted);
      }
    }
  }
}

.tc-cell--selected {
  background-color: var(--background_content);
}

.tc-row--selected {
  background-color: var(--background_content);
}

.tc-wrap {
  --color-background: var(--background_content);
}

.ce-Math {
  color: var(--text_primary);
}

.ct {
  &:before, &:after {
    background-color: var(--tooltip_black_bg);
  }
}

.tc-add-column,
.tc-add-row {
  &:hover {
    background-color: var(--background_highlighted);

    &:before {
      background-color: var(--background_highlighted);
    }
  }
}

.codex-editor {
  padding: 6px 16px;
  border-radius: var(--vkui--size_border_radius--regular);
  border: var(--thin-border) solid var(--separator_common, var(--vkui--color_separator_primary));
}

.codex-editor {
  z-index: 0 !important;
}

@media screen and (min-width: 651px) {
  .codex-editor--narrow {
    .ce-settings {
      .ce-popover {
        right: 20px;
        margin-top: 10px;
      }
    }

    .ce-toolbox {
      margin-left: 12px;
      background: transparent;

      .ce-popover {
        right: 20px;
      }
    }
  }

  .ce-toolbar {
    &__actions {
      margin-top: 2px;
    }

    &__plus {
      margin-right: 10px !important;
    }
  }

  .ce-toolbox--opened {
    .ce-toolbox__button:first-child {
      margin-left: 11px;
    }
  }
}

.timecode {
  color: var(--accent);
  cursor: pointer;
  display: inherit;
  line-height: 0;
}

.cyrillic_fallback {
  font-family: var(--font-common), sans-serif;
  font-size: var(--vkui--font_text--font_size--regular, 16px);
}

.katex-display {
  margin-top: 0 !important;

  > .katex {
    padding-left: 0 !important;
  }
}

.ce-settings__button--confirm {
  background-color: var(--destructive) !important;

  svg {
    fill: var(--white);
  }
}

.ce-block__content {
  .ce-header {
    font-size: 20px;
    font-weight: 400;
    font-family: var(--font-display), sans-serif;
  }

  .ce-paragraph {
    font-size: calc(var(--vkui--font_text--font_size--regular) + 2px);

    &[data-placeholder]:focus:empty:before {
      opacity: 1 !important;
      transition: opacity 0.1s ease;
    }
  }

  a {
    text-decoration: none;
  }

  .text-node {
    padding: 8px;
    width: calc(100% - 16px);
    border-color: var(--input_border);
    border-radius: var(--vkui--size_border_radius--regular);
    background-color: var(--field_background, var(--vkui--color_field_background));
  }
}

.cdx-marker {
  background: rgba(236, 225, 86, 0.8);
  color: initial;
  text-transform: none;
  border-radius: 4px;
  padding: 1px 3px;

  body[scheme=space_gray] & {
    background: rgba(255, 245, 128, 0.34);
  }
}

.cdx-button {
  background: var(--background_content);

  &:hover {
    background: var(--background_hover);
  }
}

.image-tool--loading .image-tool__image {
  background: var(--background_content) !important;
}

.image-tool__image,
.image-tool__image-picture {
  border-radius: var(--vkui--size_border_radius--regular);
}


/** Responsive overrides */

@media (max-width: 650px) {
  .codex-editor {
    padding-bottom: 50px;
  }

  .viewport--tab {
    .ce-popover {
      bottom: calc(var(--offset) + var(--tabbar_height) + env(safe-area-inset-bottom));
    }
  }

  .ce-toolbar {
    margin-top: 4px;
    border-bottom: 1px solid var(--input_border);

    &__actions {
      margin-top: 4px;
      padding: 0 14px !important;
    }
  }

  .ce-toolbar__plus,
  .ce-toolbar__settings-btn {
    border-color: var(--vkui--color_separator_primary);
  }
}
