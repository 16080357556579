/**
 * Border scss module
 *
 * @author: exode <hello@exode.ru>
 */

@import "responsive";

$sides: top, bottom, left, right;

@mixin inset-shadow-border($color: var(--accent)) {
  box-shadow: inset 0 0 0 2px $color;
}

@mixin thin-border() {
  position: relative;

  &.white-border {
    &::after {
      box-shadow: inset 0 0 0 var(--thin-border) #d7d8d9;
    }
  }

  &::after {
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: inherit;
    pointer-events: none;
    box-shadow: inset 0 0 0 var(--thin-border) var(--separator_common, var(--vkui--color_separator_primary));
  }
}

.b-im-bubble-alternate-highlighted {
  border: 0.042em solid var(--im_bubble_border_alternate_highlighted);
}

.border-b--separator-common {
  border-bottom: 1px solid var(--separator_common);
}

.thin-border {
  @include thin-border();
}

.light\:thin-border {
  body[scheme=bright_light] & {
    @extend .thin-border;
  }
}

.dark\:thin-border {
  body[scheme=space_gray] & {
    @extend .thin-border;
  }
}

.thin-border-border {
  border: var(--thin-border) solid var(--separator_common, var(--vkui--color_separator_primary));

  &--transparent {
    border: var(--thin-border) solid transparent;
  }
}

.thin-border {
  @each $side in $sides {
    &-#{$side} {
      border-#{$side}: var(--thin-border) solid var(--input_border);
    }
  }
}

.m-thin-border {
  @include tablet() {
    @each $side in $sides {
      &-#{$side} {
        border-#{$side}: var(--thin-border) solid var(--input_border);
      }
    }
  }
}

.d-thin-border {
  @include gt-tablet() {
    @each $side in $sides {
      &-#{$side} {
        border-#{$side}: var(--thin-border) solid var(--input_border);
      }
    }
  }
}

.m-thin-border {
  @include tablet() {
    @include thin-border();
  }
}

.d-thin-border {
  @include gt-tablet() {
    @include thin-border();
  }
}

.border-separator {
  border-color: var(--separator_common) !important;
}

.accent-shadow {
  @include inset-shadow-border(var(--accent));
}

.text-primary-shadow {
  @include inset-shadow-border(var(--text_primary));
}

.text-secondary-shadow {
  @include inset-shadow-border(var(--text_secondary));
}

.text-destructive-shadow {
  @include inset-shadow-border(var(--destructive));
}

.text-positive-shadow {
  @include inset-shadow-border(var(--dynamic_green));
}

.text-destructive-shadow {
  @include inset-shadow-border(var(--destructive));
}

.light\:accent-shadow {
  body[scheme=bright_light] & {
    @extend .accent-shadow;
  }
}

.dark\:accent-shadow {
  body[scheme=space_gray] & {
    @extend .accent-shadow;
  }
}

.dark\:m-accent-shadow {
  body[scheme=space_gray] & {
    @include tablet() {
      @include inset-shadow-border();
    }
  }
}

.vk-rounded {
  border-radius: var(--vkui--size_border_radius_paper--regular);

  &-regular {
    border-radius: var(--vkui--size_border_radius--regular);
  }
}

.button-rounded {
  border-radius: var(--vkui--size_border_radius--regular);
}

.m\:vk-rounded {
  @include tablet() {
    border-radius: var(--vkui--size_border_radius_paper--regular);
  }
}
