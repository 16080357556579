/**
 * Text scss module
 *
 * @author: exode <hello@exode.ru>
 */

@mixin text-gradient() {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient {
  @include text-gradient();
}

/** Text colors from scheme */

.text-subhead {
  color: var(--text_subhead);
}

.text-action-counter {
  color: var(--text_action_counter);
}

.text-slight-gray {
  color: var(--dynamic_gray);
}

.text-link {
  color: var(--text_link);
}

.highlight-links-wrapper {
  a {
    color: var(--accent)
  }
}

.text-link-highlighted-background {
  color: var(--text_link_highlighted_background);
}

.text-muted {
  color: var(--text_muted);
}

.text-name {
  color: var(--text_name);
}

.text-placeholder {
  color: var(--text_placeholder);
}

.text-primary {
  color: var(--text_primary) !important;
}

.text-secondary {
  color: var(--text_secondary) !important;
}

.text-tertiary {
  color: var(--text_tertiary)
}

.text-accent {
  color: var(--accent) !important;
}

.text-button-tertiary {
  color: var(--button_tertiary_foreground);
}

.text-positive {
  color: var(--vkui--color_text_positive);
}

.text-destructive {
  color: var(--destructive);
}

.text-blue-light {
  color: var(--blue_light);
}

.text-bg-button-secondary {
  color: var(--button_secondary_foreground)
}

.text-bg-button-secondary--bg {
  color: var(--button_secondary_background);
}

.line-through-diagonally {
  position: relative;

  &:before {
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    position: absolute;
    border-top: 2px solid;
    border-color: var(--dynamic_red);
    transform: rotate(-10deg);
  }
}

.line-clamp-1 {
  white-space: normal;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-marker {
  display: inline-block;
  position: relative;
  margin: 0 -1em -.3em -.5em;
  padding: 0 1em .3em .9em;
  background-size: cover !important;

  body[scheme=bright_light] & {
    background: transparent url("../../images/bricks/marker-light-green.png") no-repeat 0 0;
  }

  body[scheme=space_gray] & {
    color: black !important;
    filter: invert(1);
    background: transparent url("../../images/bricks/marker-green.png") no-repeat 0 0;
  }
}

.text-underline-marker {
  position: relative;
  z-index: 1;

  &.h-after-4 {
    &:after {
      height: 4px;
    }
  }

  &:after {
    content: '';
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
    height: 4px;
    position: absolute;
    transform: skew(-12deg);
    background: linear-gradient(70deg, var(--azure_blue), var(--exode_purple));

    body[scheme=bright_light] & {
      opacity: 0.45;
    }
  }
}

.word-break,
.break-words {
  word-break: break-word !important;
}
