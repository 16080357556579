/**
 * Tappable scss module
 *
 * @author: exode <hello@exode.ru>
 */

@import "../modules/responsive";


.vkuiTappable {

  .HorizontalScroll & {
    border-radius: var(--vkui--size_border_radius_paper--regular);
  }

  @include gt-tablet() {
    &:not(button) {
      border-radius: var(--vkui--size_border_radius_paper--regular);
    }
  }

  &[aria-disabled=true].cursor-pointer {
    &[role="button"] {
      cursor: pointer;
    }
  }
}

.Popper__content {
  .vkuiSimpleCell {
    border-radius: var(--vkui--size_border_radius_paper--regular);
  }
}
