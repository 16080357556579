/**
 * Skeletons scss module
 *
 * @author: exode <hello@exode.ru>
 */

body[scheme=space_gray] {
  & .MuiSkeleton-root {
    background-color: var(--content_tint_background)
  }

  .ModalRoot {
    & .MuiSkeleton-root {
      background-color: var(--background_content)
    }
  }
}
