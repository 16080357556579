/**
 * Banner scss module
 *
 * @author: exode <hello@exode.ru>
 */

@import "scheme";

.banner__image {
  top: 0;
  right: 0;
  opacity: 0.3;

  @include light() {
    color: var(--accent);
  }

  @include dark() {
    color: var(--white) !important;
  }
}
