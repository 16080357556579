/**
 * Font scss module
 *
 * @author: exode <hello@exode.ru>
 */

@import "responsive";

@font-face {
  font-family: 'VK Sans Display';
  src: url('../../fonts/VKSansDisplay-DemiBold.woff2') format('woff2'),
  url('../../fonts/VKSansDisplay-DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

.font-vk-sans-display {
  font-family: 'VK Sans Display', serif;
}

.font-vk-common {
  font-family: var(--font-common) !important;
}

.fs-content {
  line-height: 155%;
  font-size: var(--fs-content);
}

.fs-common {
  font-size: var(--vkui--font_text--font_size--compact, 15px);
}

@for $i from 6 through 130 {
  .fs-#{$i} {
    font-size: #{$i}px;
  }
}

@for $i from 6 through 130 {
  .m\:fs-#{$i} {
    @include tablet() {
      font-size: #{$i}px !important;
    }
  }
}

@for $i from 6 through 130 {
  .d\:fs-#{$i} {
    @include gt-tablet() {
      font-size: #{$i}px !important;
    }
  }
}
